var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Generali",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].code.label,
                                                          vid: "code",
                                                          label:
                                                            "Codice Agenzia Coass",
                                                          placeholder:
                                                            "Inserisci un codice agenzia",
                                                          rules:
                                                            _vm.getRules(
                                                              "code"
                                                            ),
                                                          maxlength:
                                                            _vm.getMaxLength(
                                                              "code"
                                                            ),
                                                          disabled: true,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].code",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].code_importer.label,
                                                          vid: "code_importer",
                                                          label:
                                                            "Codice Compagnia Coass",
                                                          placeholder:
                                                            "Inserisci un codice compagnia",
                                                          rules:
                                                            _vm.getRules(
                                                              "code_importer"
                                                            ),
                                                          maxlength:
                                                            _vm.getMaxLength(
                                                              "code_importer"
                                                            ),
                                                          disabled: true,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .code_importer,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "code_importer",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].code_importer",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].title.label,
                                                          vid: "title",
                                                          label:
                                                            "Ragione Sociale Intermediario Coass",
                                                          placeholder:
                                                            "Inserisci una ragione sociale",
                                                          rules:
                                                            _vm.getRules(
                                                              "title"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].title",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].title_importer
                                                            .label,
                                                          vid: "title_importer",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .title_importer
                                                              .label,
                                                          placeholder:
                                                            "Inserisci una compagnia",
                                                          rules:
                                                            _vm.getRules(
                                                              "title_importer"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .title_importer,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "title_importer",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].title_importer",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_BONA_value
                                                            .label,
                                                          vid: "attribute_BONA_value",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_BONA_value
                                                              .label,
                                                          placeholder:
                                                            "Inserisci un addebito",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules: _vm.getRules(
                                                            "attribute_BONA_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_BONA_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_BONA_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_BONA_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_CREG_value
                                                            .label,
                                                          vid: "attribute_CREG_value",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_CREG_value
                                                              .label,
                                                          placeholder:
                                                            "Inserisci una codice fiscale o PIVA",
                                                          rules: _vm.getRules(
                                                            "attribute_CREG_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_CREG_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_CREG_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_CREG_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_INNO_value
                                                            .label,
                                                          vid: "attribute_INNO_value",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_INNO_value
                                                              .label,
                                                          placeholder:
                                                            "Inserisci una descrizione ritenuta",
                                                          rules: _vm.getRules(
                                                            "attribute_INNO_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_INNO_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_INNO_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_INNO_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_WHT_value
                                                            .label,
                                                          vid: "attribute_WHT_value",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_WHT_value
                                                              .label,
                                                          options: {
                                                            locale: "it-IT",
                                                            currencyDisplay:
                                                              "percent",
                                                            precision: 2,
                                                          },
                                                          placeholder:
                                                            "Inserisci ritenuta",
                                                          rules: _vm.getRules(
                                                            "attribute_WHT_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_WHT_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_WHT_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_WHT_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_TKTP_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_TKTP_value
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_TKTP_value
                                                              .label,
                                                          placeholder:
                                                            "Inserisci una modalità di pagamento",
                                                          rules: _vm.getRules(
                                                            "attribute_TKTP_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_TKTP_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_TKTP_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_TKTP_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "sector_id",
                                                          name: "sector_id",
                                                          label:
                                                            "Conto collegato",
                                                          options: _vm.sectors,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .sector_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "sector_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].sector_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("correspondence-form", {
                                          attrs: {
                                            beECForm: _vm.beForm[_vm.cor],
                                            repository: _vm.cor,
                                            coass: _vm.isCoass,
                                          },
                                          model: {
                                            value: _vm.correspondence_form,
                                            callback: function ($$v) {
                                              _vm.correspondence_form = $$v
                                            },
                                            expression: "correspondence_form",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1708832727
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }